<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                @change="dateCheck"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select size="small" @input="handleInputStatusFilter" v-model="status_filter" multiple placeholder="Select">
                <el-option
                  v-for="item in statusFilterOptions"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select> &nbsp;
              <el-select v-model="dateFilterType" size="small">
                <el-option v-for="(item, i) in dateFilterTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
              <!-- <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-position">Send to Email</el-button> -->
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Ticket No</th>
                    <th>Status</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Started At</th>
                    <th>Resolved At</th>
                    <th>Duration</th>
                    <th>Channel</th>
                    <th>Agent Name</th>
                    <th>Group</th>
                    <th>First Response Date</th>
                    <th>First Response Time</th>
                    <th>Labels</th>
                    <th>CSAT Rating</th>
                    <th>CSAT Comment</th>
                    <th>Source of Fund</th>
                    <th>Price</th>
                    <th>Reference Number</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="(item, i) in reportList" :key="i" @click="manage(workspace)" style="cursor: pointer">
                    <td>#{{ item.code }}</td>
                    <td>
                      <span style="padding: 6px" class="badge ml-1" :class="item.status_color_str">{{ upperFirst(item.status) }}</span>
                    </td>
                    <td>
                      {{ item.contacts[0].name }}
                    </td>
                    <td>
                      {{ item.whatsapp_contact ? item.whatsapp_contact.phone_number : item.contacts[0].phone_number }}
                    </td>
                    <td>
                      {{ formatDate(item.opened_at) }}
                    </td>
                    <td>
                      {{ formatDate(item.resolved_at) }}
                    </td>
                    <td>
                      {{ countDuration(item.opened_at, item.resolved_at) }}
                    </td>
                    <td class="text-capitalize">
                      {{ item.channels_used.join(', ') }}
                    </td>
                    <td>
                      {{ item.agent.user ? item.agent.user.name : '-' }}
                    </td>
                    <td>
                      {{ item.agent_group ? item.agent_group.name : '-' }}
                    </td>
                    <td>
                      {{ item.handling_at ? formatDate(item.handling_at) : '-' }}
                    </td>
                    <td>
                      {{ item.handling_at ? countDuration(item.opened_at, item.handling_at) : '-' }}
                    </td>
                    <td>
                      <el-tag size="mini" v-for="(l) in item.labels" :key="l.id" :style="{ 'background-color': l.color, 'color': 'white' }" class="mr-1">
                        {{ l.title }}
                      </el-tag>
                    </td>
                    <td>
                      <span v-if="item.rating && item.rating.rate_value">
                        <font-awesome style="color: #D4AF37" icon="star" v-for="i in item?.rating.rate_value" :key="i"/>
                        <font-awesome :icon="['far', 'star']" v-for="i in (5 - item?.rating.rate_value)" :key="i"/>
                        {{ item.rating?.rate_value }} / 5
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <span v-if="item.rating && item.rating.rate_value">
                        {{ item.rating.comment || '-' }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td>{{ item.source_of_fund_str || '-' }}</td>
                    <td>{{ item.price || '-' }}</td>
                    <td>{{ item.reference_number || '-' }}</td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="totalRow == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="totalRow > page_limit" v-model="currentPage" :total-rows="totalRow" :per-page="page_limit"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, upperFirst, difference } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'HelpdeskReports',
  metaInfo: {
    title: 'Helpdesk Reports',
  },
  data() {
    return {
      upperFirst,
      showModal: false,
      showModalEdit: false,
      page_limit: 50,
      loading: {
        table: false,
      },
      form: {
        name: '',
        ws_product_id: '',
      },
      dateFilterTypeOptions: [
        {
          value: 'open',
          text: 'Open Date',
        },
        {
          value: 'handling',
          text: 'Handling Date',
        },
        {
          value: 'resolved',
          text: 'Resolved Date',
        },
      ],
      dateFilterType: this.$route.query.date_filter_type || 'resolved',
      status_filter: this.$route.query.status_filter || ['resolved'],
      statusFilterOptions: [
        {
          value: 'all',
          text: 'All',
        },
        {
          value: 'open',
          text: 'Open',
        },
        {
          value: 'handling',
          text: 'Handling',
        },
        {
          value: 'resolved',
          text: 'Resolved',
        },
      ],
      editedId: '',
      reportList: [],
      loaderStack: 0,
      loader: null,
      search: {
        date_range: [
          this.$route.query.start_date || new Date(),
          this.$route.query.end_date || new Date(),
        ],
      },
      page: this.$route.query.page || 1,
      totalRow: 0,
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  methods: {
    dateCheck() {
      const [start_date, end_date] = this.search.date_range;
      const minTime = moment(start_date);
      const maxTime = moment(end_date);
      const diff = maxTime.diff(moment(minTime), 'd');
      if (diff > 30) {
        this.$message({
          title: this.$t('general.error.general'),
          type: 'warning',
          message: 'Reporting period that can be selected for 31 days',
          duration: 20 * 100,
          showClose: true,
        });
        this.search.date_range = [start_date, start_date];
      }
    },
    countDuration(start, end) {
      return start && end ? humanizeDuration(moment(start).diff(moment(end)), { largest: 2, round: true }) : '-';
    },
    formatDate(string, format = 'DD MMM YYYY, HH:mm') {
      return string ? moment(string).format(format) : '-';
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            if (isEmpty(this.search.date_range)) {
              this.search.date_range = [new Date(), new Date()];
            }
            const [start_date, end_date] = this.search.date_range;
            const response = await reportApi.download({
              workspace_id: this.activeWorkspace._id,
              start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
              end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
              date_filter_type: this.dateFilterType,
              status_filter: this.status_filter,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    async getList() {
      this.loading.table = true;
      if (isEmpty(this.search.date_range)) {
        this.search.date_range = [new Date(), new Date()];
      }
      this.dateCheck();
      const [start_date, end_date] = this.search.date_range;
      const options = {
        page: this.page,
        start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
        end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
        date_filter_type: this.dateFilterType,
        status_filter: this.status_filter,
      };
      const response = await reportApi.getList({
        workspace_id: this.activeWorkspace._id,
        ...options,
      }).catch(() => {});
      this.$router.replace({ query: options });
      this.loading.table = false;
      await popupErrorMessages(response);
      const channel_alias = {
        whatsapp: 'WhatsApp',
      };
      const source_list = {
        postpaid: 'Postpaid',
        balance: 'Balance',
      };
      this.reportList = response.data.rows.map((item) => {
        item.status_color_str = 'badge-danger';
        if (item.status === 'resolved') {
          item.status_color_str = 'badge-success';
        } else if (item.status === 'handling') {
          item.status_color_str = 'badge-primary';
        }
        item.whatsapp_contact = item.contacts.find((v) => v.channel === 'whatsapp');
        item.channels_used = item.channels.map((v) => channel_alias[v] || upperFirst(v));
        item.source_of_fund_str = '-';
        if (item.source_of_fund) {
          item.source_of_fund_str = source_list[item.source_of_fund] || 'Free Ticket Quota';
        }
        return item;
      });
      this.totalRow = response.data.count;
    },
    handleInputStatusFilter(value) {
      if (value[value.length - 1] === 'all') {
        this.status_filter = ['all'];
      } else if (value.indexOf('all') !== -1) {
        this.status_filter.splice(value.indexOf('all'), 1);
      } else if (difference(['open', 'handling', 'resolved'], this.status_filter).length === 0) {
        this.status_filter = ['all'];
      }
    },
  },
};
</script>
